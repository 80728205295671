import { pulseMerge } from './merge';
import { isFunction, isObject, isPromise } from './objects';
/**
 * @recursive
 * @param eventInput
 */
export async function evaluateEventInput(eventInput) {
    if (isPromise(eventInput)) {
        return eventInput.then((r) => evaluateEventInput(r));
    }
    if (Array.isArray(eventInput)) {
        return Promise.all(eventInput.map(evaluateEventInput));
    }
    if (isFunction(eventInput)) {
        return evaluateEventInput(Promise.resolve(eventInput()));
    }
    if (isObject(eventInput)) {
        const eventKeys = Object.getOwnPropertyNames(eventInput);
        const evaluatedEventKeys = eventKeys.reduce((acc, key) => {
            const eventInputProperty = eventInput[key];
            if (isObject(eventInputProperty) || isFunction(eventInputProperty) || isPromise(eventInputProperty)) {
                return acc.concat(evaluateEventInput(eventInputProperty));
            }
            return acc.concat([eventInputProperty]);
        }, []);
        return Promise.all(evaluatedEventKeys).then((resolvedEventKeys) => resolvedEventKeys.reduce((evaluatedEventInput, resolvedEventKey, index) => pulseMerge(evaluatedEventInput, { [eventKeys[index]]: resolvedEventKey }), {}));
    }
    return Promise.resolve(eventInput);
}
export function evaluateAndFlatten(builder) {
    if (Array.isArray(builder)) {
        return Promise.all(builder.map((b) => evaluateEventInput(b))).then((results) => pulseMerge({}, ...results.filter(isObject)));
    }
    return evaluateEventInput(builder);
}
/**
 * Function for getting device type taken from the old SDK. It performs as well
 * as one could expect from the size. It could be replaced with 'ua-parser-js'
 * at the cost of 11kb minimized.
 *
 * @param {string} userAgent
 *
 * @returns {string} the device type the user is using
 */
export function deviceType(userAgent) {
    const knownDevices = {
        mobile: [
            /iPhone;/,
            /iPod;/,
            /iPod touch;/,
            /^HTC/,
            /Fennec/,
            /IEMobile/,
            /BB10;/,
            /BlackBerry/,
            /SymbianOS.*AppleWebKit/,
            /^Mozilla.*Mobile.*Firefox/,
            /^Mozilla.*Chrome.*Mobile/,
            /Opera Mobi/,
            /Android.*Mobile/,
            /Android.*Mini/,
            /Symbian/,
            /^SonyEricsson/,
            /^Nokia/,
            /^SAMSUNG/,
            /^LG/,
        ],
        tablet: [/iPad/, /Android/],
    };
    /**
     * Hermes' apps (Shared app-platform for media) return "Hermes"&"_app_" as part of the user-agent
     * Example UA: `Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_6)
     * AppleWebKit/605.1.15 (KHTML, like Gecko) iOS AP Hermes/64.0.0 _app_`
     * @returns {boolean} Returns true if the user is inside the app.
     */
    function isHermesApp() {
        return userAgent.search(/Hermes.*_app_/) !== -1;
    }
    function isDeviceIn(devicesList) {
        for (let i = 0; i < devicesList.length; i += 1) {
            if (devicesList[i].test(userAgent)) {
                return true;
            }
        }
        return false;
    }
    if (userAgent) {
        if (isDeviceIn(knownDevices.mobile)) {
            return 'mobile';
        }
        if (isDeviceIn(knownDevices.tablet)) {
            return 'tablet';
        }
        if (isHermesApp()) {
            // If not a detected device but inside app it's most likely a tablet masking itself as desktop
            return 'tablet';
        }
    }
    return 'desktop';
}
export function dateTime() {
    return new Date().toISOString();
}
