export const isBrowser = typeof window !== 'undefined' && typeof window.document !== 'undefined';
const initializeStorage = (storageType) => {
    if (isBrowser)
        return window[storageType];
};
const webApi = {
    crypto: isBrowser ? crypto : globalThis.crypto,
    localStorage: initializeStorage('localStorage'),
    sessionStorage: initializeStorage('sessionStorage'),
};
export default webApi;
