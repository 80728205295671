/**
 * This funciton works like lodash deep merge, but in addition it also merges the result of promises:
 * function conflictResolver(objValue: unknown, srcValue: unknown): Promise<EventInput> | undefined {
 *  if (objValue instanceof Promise || srcValue instanceof Promise) {
 *    return Promise.all([objValue, srcValue]).then(([obj, src]) => mergeWith({}, obj, src, conflictResolver));
 *  }
 *  return undefined;
 * }
 * _.mergeWith({}, a,b,c conflictResolver);
 */
export function pulseMerge(target, ...sources) {
    for (const source of sources) {
        mergeValue(target, source);
    }
    return target;
    function innerMerge(target, source) {
        for (const [key, value] of Object.entries(source)) {
            // @ts-ignore
            target[key] = mergeValue(target[key], value);
        }
    }
    function mergeValue(targetValue, value) {
        if (Array.isArray(value)) {
            if (!Array.isArray(targetValue)) {
                return [...value];
            }
            else {
                for (let i = 0, l = value.length; i < l; i++) {
                    targetValue[i] = mergeValue(targetValue[i], value[i]);
                }
                return targetValue;
            }
        }
        else if (targetValue instanceof Promise || value instanceof Promise) {
            return Promise.all([targetValue, value]).then(([a, b]) => pulseMerge({}, a, b));
        }
        else if (typeof value === 'object') {
            if (targetValue && typeof targetValue === 'object') {
                innerMerge(targetValue, value);
                return targetValue;
            }
            else {
                return value ? { ...value } : value;
            }
        }
        else {
            return value ?? targetValue ?? undefined;
        }
    }
}
