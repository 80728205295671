export function isObject(value) {
    const type = typeof value;
    return value != null && (type === 'object' || type === 'function');
}
export function isPlainObject(value) {
    return isObject(value) && !Array.isArray(value);
}
export function isFunction(value) {
    return typeof value === 'function';
}
export function isString(s) {
    return Object.prototype.toString.call(s) === '[object String]';
}
export function isPromise(s) {
    return s instanceof Promise;
}
export function isObjectEmpty(obj) {
    for (const prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
            return false;
        }
    }
    return true;
}
