export function throttle(func, wait = 0) {
    let timeout;
    let lastCallTime = 0;
    const f = function (...args) {
        const now = Date.now();
        const remaining = wait - (now - lastCallTime);
        if (remaining <= 0 || remaining > wait) {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
            lastCallTime = now;
            // @ts-ignore
            func.apply(this, args);
        }
        else if (!timeout) {
            timeout = setTimeout(() => {
                lastCallTime = Date.now();
                timeout = null;
                // @ts-ignore
                func.apply(this, args);
            }, remaining);
        }
    };
    f.cancel = () => {
        if (timeout) {
            clearTimeout(timeout);
        }
    };
    return f;
}
