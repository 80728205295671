import logger from 'loglevel';
import WebApi from '../WebApi';
import { isString } from '@spt-tracking/utils';
const CIS_KEY = 'cis';
const COLLECTOR_KEY = 'collector';
const getRemoteConfigStorageKey = `_pulse.internal.remote.config`;
let inMemoryConfig = null;
export async function fetchRemoteConfig(url) {
    const response = await fetch(url, {
        method: 'GET',
    });
    return response.json();
}
export async function fetchRemoteConfigWithRetry(url, retries = 3) {
    try {
        return await fetchRemoteConfig(url);
    }
    catch (err) {
        if (retries > 1) {
            return fetchRemoteConfigWithRetry(url, retries - 1);
        }
        throw err;
    }
}
export function readConfigFromStorage() {
    try {
        return JSON.parse(WebApi.localStorage?.getItem(getRemoteConfigStorageKey) ?? '');
    }
    catch (_err) {
        return null;
    }
}
function writeConfigToStorage(config, url) {
    try {
        const configPersistence = {
            config,
            metadata: {
                remoteConfigUrl: url,
                updatedAt: new Date().toISOString(),
            },
        };
        WebApi.localStorage?.setItem(getRemoteConfigStorageKey, JSON.stringify(configPersistence));
        return configPersistence;
    }
    catch (_err) {
        return null;
    }
}
export function clearConfigFromStorage() {
    try {
        WebApi.localStorage?.removeItem(getRemoteConfigStorageKey);
        return true;
    }
    catch (_err) {
        return false;
    }
}
export function clearInMemoryConfig() {
    inMemoryConfig = null;
}
function isConfigValid(config) {
    const isConfigValid = !!config;
    logger.debug(`RemoteConfig: isConfigValid: ${isConfigValid}`);
    return isConfigValid;
}
function isConfigExpired(updatedAt, ttlMinutes) {
    const isConfigExpired = !updatedAt ||
        !ttlMinutes ||
        new Date(updatedAt).getTime() + Math.min(ttlMinutes, 10080) * 60000 <= new Date().getTime();
    logger.debug(`RemoteConfig: isConfigExpired: ${isConfigExpired}`);
    return isConfigExpired;
}
export function shouldSyncRemoteConfig(url, config, metadata) {
    return (!isConfigValid(config) ||
        isConfigExpired(metadata?.updatedAt, config?.['ttl-minutes']) ||
        url !== metadata?.remoteConfigUrl);
}
export function resolveConfigServicesByTag(tag, config) {
    if (isString(tag) && config) {
        let services = (config.scopes && config.scopes[tag]?.services) || null;
        if (!services && tag !== 'default') {
            services = config.scopes.default.services;
        }
        return services;
    }
    return null;
}
let onGoingSync = null;
export async function syncRemoteConfig(tag = 'default', url) {
    try {
        if (!inMemoryConfig) {
            inMemoryConfig = readConfigFromStorage();
        }
        if (!inMemoryConfig || shouldSyncRemoteConfig(url, inMemoryConfig.config, inMemoryConfig.metadata)) {
            let remoteConfig;
            if (onGoingSync) {
                remoteConfig = await onGoingSync;
            }
            else {
                onGoingSync = fetchRemoteConfigWithRetry(url);
                remoteConfig = await onGoingSync;
                onGoingSync = null;
            }
            inMemoryConfig = writeConfigToStorage(remoteConfig, url);
        }
        return resolveConfigServicesByTag(tag, inMemoryConfig?.config);
    }
    catch (err) {
        logger.debug(err);
        onGoingSync = null;
        return null;
    }
}
