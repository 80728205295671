import { generateObjectSDRN } from './objects';
import { isString } from '@spt-tracking/utils';
// https://github.schibsted.io/spt-dataanalytics/event-formats/blob/master/schema/master/common-definitions.json#L295
const originIdRegex = new RegExp('^unknown$|^sdrn:(.*)$');
function isOriginSDRN(originId) {
    return isString(originId) && originIdRegex.test(originId);
}
export default function origin(input) {
    const { origin: originInput, provider } = input || {};
    const { id: originInputId, type: originInputType, ...rest } = originInput || {};
    const { id: providerId } = provider || {};
    // If the id of origin has already been formatted upstream, we don't want to do it again
    // This can happen, since previously we allowed the id through without any formatting and
    // brands had to do it on their end to get it correctly.
    const formattedOriginId = isOriginSDRN(originInputId)
        ? originInputId
        : generateObjectSDRN(originInputId, providerId, originInputType);
    const base = {
        ...(formattedOriginId && { '@id': formattedOriginId }),
        ...(originInputType && { '@type': originInputType }),
    };
    return Object.assign({}, base, rest);
}
export const defaultValue = () => undefined;
