import { isObjectEmpty } from '@spt-tracking/utils';
/**
 * Format experiment metadata
 */
export default function experimentMetadata(input) {
    const { errors, assignmentId, requestTimestamp } = (input || {}).experimentMetadata || {};
    const formattedExperimentMetadata = {};
    if (errors && errors.length) {
        formattedExperimentMetadata.errors = errors;
    }
    if (assignmentId) {
        formattedExperimentMetadata.assignmentId = assignmentId;
    }
    if (requestTimestamp) {
        formattedExperimentMetadata.requestTimestamp = requestTimestamp;
    }
    return isObjectEmpty(formattedExperimentMetadata) ? undefined : formattedExperimentMetadata;
}
export function defaultValue() {
    return undefined;
}
