import { getActor } from './actor';
import { cisSync } from './cis';
/**
 * Get the includeAdvertising flag, set on initialisation.
 * @private
 * @see constructor
 * @category Identifiers
 */
const getIncludeAdvertising = (sdkState) => {
    return sdkState.includeAdvertising;
};
/**
 * Get the advertisement id (adId) from the current browser. A promise is returned
 * because a CIS call is done to generate a new adId if no cookie is
 * found.
 *
 * *Note*: This method will return a Promise that is always rejected on node
 * environments.
 * @category Identifiers
 */
const getAdId = async (builders, sdkState) => {
    const { adId } = await cisSync({ actor: getActor(builders) }, builders, sdkState);
    return adId;
};
/**
 * Get the Advertisement Identifiers for a Vendor or all Vendors.
 * @param builders
 * @param sdkState
 * @param [vendors]
 * @category Identifiers
 */
const getAdvertisingIdentifiers = async (builders, sdkState) => {
    const { vendors } = sdkState;
    if (!Array.isArray(vendors)) {
        throw new Error("'vendors' given is not an array as expected");
    }
    const { adId, ppId } = await cisSync({ actor: getActor(builders) }, builders, sdkState);
    const identifiers = {};
    if (!ppId) {
        return {};
    }
    vendors.forEach((vendorName) => {
        const vendor = ppId[vendorName];
        if (vendor) {
            if (vendorName === 'xandr' && adId) {
                identifiers[vendorName] = { ...vendor, adId };
            }
            else {
                identifiers[vendorName] = vendor;
            }
        }
    });
    return identifiers;
};
export { getAdId, getIncludeAdvertising, getAdvertisingIdentifiers };
